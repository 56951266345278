/**
 * Load SVG Sprites with AJAX
 * @module ajax-icons
 */

const themeName = document.getElementsByTagName('body')[0].classList[0];

if (themeName === 'theme-boschtt') {
  // theme
  const themeIcons = new XMLHttpRequest();
  themeIcons.onload = () => {
    document.body.insertBefore(themeIcons.responseXML.documentElement, document.body.childNodes[0]);
  };

  themeIcons.open('GET', CONF.SVG_THEME_SPRITE);
  themeIcons.send();
} else {
  const commonIcons = new XMLHttpRequest();
  commonIcons.onload = () => {
    document.body.insertBefore(commonIcons.responseXML.documentElement, document.body.childNodes[0]);
  };

  commonIcons.open('GET', CONF.SVG_SPRITE);
  commonIcons.send();
}
