import './libs/ajax-icons';
import { isThere } from './libs/utils';

/**
 * ##### This components must be always loaded
 */
import(/* webpackChunkName: "basket-NG" */ 'basket'); // New basket functions
import(/* webpackChunkName: "navbar-left" */ 'navbar-left/navbar-left.imports.js');
import(/* webpackChunkName: "navbar-right" */ 'navbar-right/navbar-right.imports.js');
import(/* webpackChunkName: "sidebar-left" */ 'sidebar-left/sidebar-left.imports.js');
import(/* webpackChunkName: "sidebar-right" */ 'sidebar-right/sidebar-right.imports.js');
import(/* webpackChunkName: "copyToClipboard" */ 'copyToClipboard/copyToClipboard.imports.js');
import(/* webpackChunkName: "modal" */ 'modal/modal.imports.js');

/**
 * ##### 0. Component must be always loaded
 * FIXME: This component should be reworked :(
 * JQquery plugins used not only within gallery component
 * i.e. $(document).initHeadlineElementFunctions(); 7f0304ec35797a5c5fb0ccb51fefc9e37dba8ebb: Performs headline element initialization generally, not only in gallery
 */
import(/* webpackChunkName: "gallery" */ 'gallery/gallery.imports.js');

/**
 * ##### 1. Component with conditional imports
 * FIXME: This components are s*** with missleading name :(
 */
isThere('.daterange') && import(/* webpackChunkName: "daterange" */ 'daterange');
isThere('.brands') && import(/* webpackChunkName: "brands" */ 'brands/brands.imports.js');
isThere('.js-filterByID') && import(/* webpackChunkName: "filterbyid" */ 'filterbyid/filterbyid.imports.js');
isThere('.js-showCountedElmElements') &&
  import(/* webpackChunkName: "headlineInfo" */ 'headlineInfo/headlineInfo.imports.js');
isThere('.js-leavePageInfoWhileRequest') &&
  import(
    /* webpackChunkName: "leavePageInfoWhileRequest" */ 'leavePageInfoWhileRequest/leavePageInfoWhileRequest.imports.js'
  );
isThere('.sidebar .nav') && import(/* webpackChunkName: "navigation" */ 'navigation/navigation.imports.js');
isThere('.js-overviewButton') &&
  import(/* webpackChunkName: "overviewButton" */ 'overviewButton/overviewButton.imports.js');
isThere('.filter-input') && import(/* webpackChunkName: "filterInput" */ 'filterInput/filterInput.imports.js');
isThere('.searchfield') && import(/* webpackChunkName: "searchField" */ 'searchField/searchField.imports.js');
isThere('.searchTab__btnOptions') && import(/* webpackChunkName: "searchTab" */ 'searchTab/searchTab.imports.js');
isThere('.js-shareButton') && import(/* webpackChunkName: "shareButton" */ 'shareButton/shareButton.imports.js');
isThere('.js-hasToolTip') && import(/* webpackChunkName: "toolTip" */ 'toolTip/toolTip.imports.js');
isThere('.grid-stack') && import(/* webpackChunkName: "widget" */ 'widget/widget.imports.js');
isThere('.uploadDrop') && import(/* webpackChunkName: "uploadDrop" */ 'uploadDrop/uploadDrop.imports.js');

/**
 * ##### 2. Component with conditional imports
 * FIXME: speachless awesome :o
 */
isThere('.dropzone:not(#jsSimpleDropzone)') &&
  import(/* webpackChunkName: "dropzone" */ 'dropzone/dropzone.imports.js');
isThere('.dropzone-previews:not(#jsSimpleDropzone)') &&
  import(/* webpackChunkName: "dropzone" */ 'dropzone/dropzone.imports.js');
isThere('.productstage') && import(/* webpackChunkName: "productStage" */ 'productStage/productstage.imports.js');
import(/* webpackChunkName: "basket" */ 'basket/basket.imports.js');
import(/* webpackChunkName: "showHideAccordionControls" */ 'showHideAccordionControls/showhideControls.imports.js');
import(/* webpackChunkName: "select" */ 'select/select.imports.js');

/**
 * ##### 3. Component with conditional imports
 * TODO: Improve code, join/split components, no jQuery Plugins for trivial functions
 */
isThere('.autoCompleteInput') &&
  import(/* webpackChunkName: "autoCompleteInput" */ 'autoCompleteInput/autoCompleteInput.imports.js');
isThere('.dataTable') && import(/* webpackChunkName: "dataTable" */ 'dataTable/dataTable.imports.js');
isThere('.statistics_chart') &&
  import(/* webpackChunkName: "statistics_chart" */ 'statistics_chart/statistics_chart.imports.js');
isThere('.deSelectAll') && import(/* webpackChunkName: "deSelectAll" */ 'deSelectAll/deSelectAll.imports.js');
isThere('.dropdown') && import(/* webpackChunkName: "dropdown" */ 'dropdown/dropdown.imports.js');
isThere('.fileExtFilter') && import(/* webpackChunkName: "fileExtFilter" */ 'fileExtFilter/fileExtFilter.imports.js');
isThere('.manual') && import(/* webpackChunkName: "manual" */ 'manual/manual.imports.js');
isThere('.polyCheckbox') && import(/* webpackChunkName: "polyCheckbox" */ 'polyCheckbox/polyCheckbox.imports.js');
// isThere('.selectedOptions') && import(/* webpackChunkName: "selectedOptions" */ 'selectedOptions/selectedOptions.imports.js');
isThere('.swiper-container') ||
  (isThere('.dashboard-widgets') && import(/* webpackChunkName: "slider" */ 'slider/slider.imports.js'));
isThere('.basketview') && import(/* webpackChunkName: "" */ 'basketview');
